import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';

// Styles
import './Header.css';

// Images
import logo from '../../assets/images/logo.png';

export default function Header() {
  
  useEffect(() => {
    var mobileMenuLinks = document.querySelectorAll('.mobile-menu-wrapper .menu-link');

    for (let i = 0; i < mobileMenuLinks.length; i++) {
      mobileMenuLinks[i].addEventListener("click", () => {
        toggleMenu();
      });
    }
  }, [])

  function toggleMenu() {

    var mobileMenu = document.querySelector('.header-wrapper .mobile-menu-wrapper');
    var bodyHtml = document.querySelector('body');
    var hamburgerMenu = document.querySelector('.header-wrapper .hamburger-menu')

    if (mobileMenu.classList.contains('active')) {
      mobileMenu.classList.remove('active');
      bodyHtml.style.overflowY = "auto";
      hamburgerMenu.classList.remove('active');
    } else {
      mobileMenu.classList.add('active');
      bodyHtml.style.overflowY = "hidden";
      hamburgerMenu.classList.add('active');
    }
  }

  return(
    <div className="header-wrapper">
      <header>
        <a className="logo" href="/"><img src={logo} alt="Logo"/></a>

        <div className="menu-wrapper">
          <ul className="menu">
            <li className="menu-link"><NavLink to="/quem-somos">Quem somos</NavLink></li>
            <li className="menu-link"><NavLink to="/consignado-privado">Consignado Privado</NavLink></li>
            <li className="menu-link"><NavLink to="/para-voce">Para você</NavLink> </li>
            <li className="menu-link"><NavLink to="/para-empresas">Para empresas</NavLink> </li>
            <li className="menu-link"><a href="/blog/">Blog</a> </li>
            <li className="menu-link"><NavLink to="/ajuda">Ajuda</NavLink></li>
          </ul>
          <NavLink to="/entre-agora" className="sign-in">Entre Agora</NavLink>
        </div>

        <div className="mobile-menu-wrapper">
          <div className="menu-content">
            <ul className="menu">
              <li className="menu-logo"><NavLink to="/" className="logo"><img src={logo} alt="Logo"/></NavLink></li>
              <li className="menu-link"><NavLink to="/quem-somos">Quem somos</NavLink></li>
              <li className="menu-link"><NavLink to="/consignado-privado">Consignado Privado</NavLink></li>
              <li className="menu-link"><NavLink to="/para-voce">Para você</NavLink> </li>
              <li className="menu-link"><NavLink to="/para-empresas">Para empresas</NavLink> </li>
              <li className="menu-link"><a href="/blog">Blog</a></li>
              <li className="menu-link"><NavLink to="/ajuda">Ajuda</NavLink></li>
              <li className="menu-link"><NavLink to="/entre-agora" className="sign-in">Entre Agora</NavLink></li>
              <ul className="social-wrapper">
                <li className="social-link"><a href="https://www.facebook.com/Monee-596110767717942/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                <li className="social-link"><a href="https://api.whatsapp.com/send?phone=5562992474584" target="_blank" rel="noopener noreferrer">WhatsApp</a></li>
                <li className="social-link"><a href="https://www.instagram.com/monee_br/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                <li className="social-link"><a href="#" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
              </ul>
            </ul>
          </div>
        </div>

        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </header>
    </div>
  )
}