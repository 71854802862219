import React, { useState } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from '@material-ui/core/Slider';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// Components
import Banner from '../../components/Banner';
import Form from '../../components/Form';

// Images
import pageBackground from '../../assets/images/home-background.jpg';
import TaxesIcon from '../../assets/icons/pg-taxas.png';
import AccountIcon from '../../assets/icons/pg-contas.png';
import NegativeIcon from '../../assets/icons/pg-negativo.png';
import ContactIcon from '../../assets/icons/pg-contato.png';
import RequestIcon from '../../assets/icons/pg-pedido.png';
import PartnershipIcon from '../../assets/icons/pg-parceria.png';
import ReleaseIcon from '../../assets/icons/pg-liberacao.png';
import {ReactComponent as RightArrow} from '../../assets/icons/chevron-right.svg';

// Styles
import './Home.css';
import 'swiper/swiper-bundle.css'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Home({ history }) {
  const [deadline, setDeadline] = useState(6);
  const [value, setValue] = useState(1000);
  const [valueWithDot, setValueWithDot] = useState('1.000');

  const muiTheme = createMuiTheme({
    overrides:{
      MuiSlider: {
        thumb:{
        color: "#049F77",
        },
        track: {
          color: '#049F77',
        },
        rail: {
          color: 'gray'
        }
      }
  }
  });

  function handleSimulate() {
    history.push({
      pathname: '/simulacao',
      state: {
        deadline,
        value
      }
    })
  }

  function insertDotInValue(selectedValue) {
    if (selectedValue === 1000) {
      selectedValue = '1000'
      let newValue = selectedValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      setValueWithDot(newValue);
    } else {
      let newValue = selectedValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      setValueWithDot(newValue);
    }
  }

  function handleValueChange(event, newValue) {
    setValue(newValue)
    insertDotInValue(newValue)
  }

  function handleDeadline(event, newDeadline) {
    setDeadline(newDeadline)
  }

  return(
    <div id="home">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        className="hero-content"
      >
        <SwiperSlide>
          <Banner
            bannerImage={pageBackground}
            title="CRÉDITO PARA REALIZAR UM SONHO OU RESOLVER UM PROBLEMA?"
            subtitle="Por que não agora?"
            hashtag="#PORQUEAVIDANAOPODEESPERAR"
            extra="Serviços Financeiros"
          />
        </SwiperSlide>
      </Swiper>
      
      <div className="home-wrapper">
        <div className="container">
          <div className="first-section">
            <div className="title-wrapper">
              <h2 className="title">Crédito Consignado<br/>Privado</h2>
              <span>A melhor opção agora!</span>
            </div>
            <div className="simulation">
              <h2 className="title">Simule agora!</h2>
              <div className="form-group">
                <div className="form-control">
                  <div className="min-max">
                    <span>6 Meses</span>
                    <span>36 Meses</span>
                  </div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      value={deadline}
                      name="Prazo"
                      min={6}
                      max={36}
                      step={1}
                      onChange={handleDeadline}
                    />
                  </ThemeProvider>
                  <div className="value-result">
                    <p>Prazo: <span>{deadline}</span> Meses</p>
                  </div>
                </div>
                <div className="form-control">
                  <div className="min-max">
                    <span>R$1.000</span>
                    <span>R$50.000</span>
                  </div>
                  <ThemeProvider theme={muiTheme}>
                    <Slider
                      value={value}
                      name="Valor"
                      min={1000}
                      max={50000}
                      step={500}
                      onChange={handleValueChange}
                    />
                  </ThemeProvider>
                  <div className="value-result">
                    <p>Valor: <span>R${valueWithDot}</span></p>
                  </div>
                </div>
              </div>
              <div className="btn-control">
                <button className="btn-simulate" onClick={handleSimulate}>Simular</button>
              </div>
            </div>
          </div>
          
          <div className="second-section">
            <div className="card-wrapper">
              <div className="card">
                <img src={TaxesIcon} alt="Taxas mais competitivas" title="Taxas mais competitivas"/>
                <h3 className="title">TAXAS MAIS<br/>COMPETITIVAS</h3>
                <p className="text">
                  Oferecemos taxas menores que as do empréstimo pessoal, cheque especial e cartão de crédito.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="card">
                <img src={AccountIcon} alt="Contas em dia" title="Contas em dia"/>
                <h3 className="title">CONTAS EM DIA</h3>
                <p className="text">
                  Você não precisa se preocupar com mais um boleto para pagar já que as parcelas são descontadas da folha de pagamento.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="card">
                <img src={NegativeIcon} alt="Está negativo?" title="Está negativado?"/>
                <h3 className="title">ESTÁ NEGATIVADO?</h3>
                <p className="text">
                  Sem problemas. O desconto em folha também facilita o crédito pra sua vida.
                </p>
              </div>
            </div>

            <h2 className="section-title">
              Como Funciona?
            </h2>

            <div className="how-it-works">
              <div className="content">
                <img src={ContactIcon} className="icon" alt="Contato"/>
                <h3 className="title">1. Contato</h3>
                <p className="text">
                  Indique sua empresa e dê o primeiro passo para realizar o que você tem vontade.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="content">
                <img src={PartnershipIcon} className="icon" alt="Parceria"/>
                <h3 className="title">2. Parceria</h3>
                <p className="text">
                A Monee e o RH da sua empresa se unem para que você possa ter acesso ao empréstimo com a melhor taxa do mercado.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="content">
                <img src={RequestIcon} className="icon" alt="Pedido"/>
                <h3 className="title">3. Pedido</h3>
                <p className="text">
                  Agora é só fazer o pedido do crédito desejado na nossa plataforma online.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="content">
                <img src={ReleaseIcon} className="icon" alt="Liberação do Crédito"/>
                <h3 className="title">4. Liberação do Crédito</h3>
                <p className="text">
                  Após 24 horas da liberação do empréstimo, o dinheiro estará na sua conta e você com mais liberdade em suas mãos.
                </p>
              </div>
            </div>
          </div>
          <Form/>
        </div>
      </div>
    </div>
  )
}