import React from 'react';

// Images
import logo from '../../assets/images/logo.png';

// Styles
import './PopUp.css';

export default function PopUp(props) {

  return(
    <div id="popup-wrapper" style={props.open ? {display: "flex"} : {display: "none"}}>
      <div className="popup-content">
        <span className="close" onClick={(e) => props.closePopUp(true)}>&times;</span>
        <h1 className="title">{props.title}</h1>
        <p className="message">{props.message}</p>
        <img src={logo} alt="" className="logo"/>
      </div>
    </div>
  )
}