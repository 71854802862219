import React from 'react';

// Components
import Hero from '../../components/Hero';
import Form from '../../components/Form';

// Images
import privatePayrollBackground from '../../assets/images/private-payroll-background.jpg';
import img01 from '../../assets/images/private-payroll-img01.jpg';
import {ReactComponent as AlertIcon} from '../../assets/icons/alert.svg';
import {ReactComponent as RightArrow} from '../../assets/icons/chevron-right.svg';
import {ReactComponent as SignUp} from '../../assets/icons/signup.svg';
import {ReactComponent as Analysis} from '../../assets/icons/analysis.svg';
import {ReactComponent as Money} from '../../assets/icons/money.svg';

// Styles 
import './PrivatePayroll.css';

export default function PrivatePayroll() {

  return(
    <div id="privatePayroll">
      <Hero 
        title="Empréstimo consignado privado com taxas competitivas."
        subtitle="CONSIGNADO PRIVADO"
        text="Experimente o poder de fazer o que você tem vontade agora. Por que não hoje? A melhor opção de empréstimo pessoal."
        image={privatePayrollBackground}
      />
      <div className="container">
        <section id="firstSection">
          <div className="first-content">
            <div className="img-content">
              <img src={img01} alt=""/>
            </div>
          </div>
          <div className="second-content">
            <h3 className="subtitle">O que é empréstimo</h3>
            <h2 className="title">CONSIGNADO PRIVADO?</h2>

            <p className="text">
              Você já conhece o velho empréstimo consignado, certo? Pois o consignado privado é uma linha especial onde o valor contratado é descontado todos os meses do salário. Assim, o colaborador não precisa se preocupar com o pagamento das parcelas.
              <br/><br/>
              Na Monee, queremos ajudar você a ter independência hoje. Por isso, oferecemos uma taxa de juros justa para que possa resolver seus problemas financeiros ou realizar seus sonhos agora. Como somos uma plataforma digital, tudo isso é feito de forma simples, transparente e rápida. Afinal de contas, a vida não para.
            </p>
          </div>
        </section>
        <section id="secondSection">
          <div className="first-content">
            <div className="card">
              <AlertIcon className="alert-icon"/>
              <h4>O QUE O COLABORADOR PODE FAZER AGORA PARA TER ACESSO A ESSE TIPO DE CRÉDITO?</h4>
              <p>Precisa trabalhar para uma empresa privada que seja <strong>conveniada à Monee</strong>.</p>
            </div>
          </div>
          <div className="second-content">
            <div className="green-border"></div>
            <h2 className="title">Sua empresa já é parceira da monee?</h2>
            <p className="subtitle">Veja o que você precisa fazer para se cadastrar e ter acesso ao crédito:</p>
            <div className="inner-content">
              <div className="sub-content">
                <SignUp fill="#049F77" width="120px"/>
                <h4 className="title">FAÇA O SEU<br/>CADASTRO</h4>
                <p className="text">
                  É só escolher a opção "Colaborador" na opção Entre agora na home do site. Você vai precisar preencher alguns dados e enviar seus documentos pelo site mesmo.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="sub-content">
                <Analysis fill="#049F77" width="70px"/>
                <h4 className="title">AGUARDE A <br/>ANÁLISE FINANCEIRA</h4>
                <p className="text">
                  Não demora nada, viu? Serve para sabermos se o crédito que você precisa está de acordo com o seu perfil.
                </p>
              </div>
              <RightArrow className="arrow"/>
              <div className="sub-content">
                <Money fill="#049F77" width="70px"/>
                <h4 className="title">HORA DO DINHEIRO<br/>CAIR NA CONTA</h4>
                <p className="text">
                  Depois da análise e da aprovação, a Monee libera o crédito na sua conta em 24 horas. Rápido e fácil. Porque a vida não espera.
                </p>
              </div>
            </div>
            <Form/>
          </div>
        </section>
      </div>
    </div>
  )
}