import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Slider from '@material-ui/core/Slider';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// Components
import PopUp from '../../components/PopUp';

// Styles
import './Simulation.css';
import api from '../../services/api';

export default function Simulation(props) {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [deadline, setDeadline] = useState(6);
  const [value, setValue] = useState(1000);
  const [valueWithDot, setValueWithDot] = useState('1.000');
  const [nameRh, setNameRh] = useState('');
  const [emailRh, setEmailRh] = useState('');
  const [phoneRh, setPhoneRh] = useState('');
  const [option, setOption] = useState('');
  const [otherText, setOtherText] = useState('');
  const [showOthers, setShowOthers] = useState(false);

  const [openPopUp, setOpenPopUp] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpMessage, setPopUpMessage] = useState('');

  const muiTheme = createMuiTheme({
    overrides:{
      MuiSlider: {
        thumb:{
        color: "#049F77",
        },
        track: {
          color: '#049F77',
        },
        rail: {
          color: 'white'
        }
      }
  }
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.location.state !== undefined) {
      setDeadline(props.location.state.deadline)
      setValue(props.location.state.value)
      if (props.location.state.value === 1000) {
        insertDotInValue(1000)
      } else {
        insertDotInValue(props.location.state.value)
      }
      
    }
    
  }, [])

  function handleOption(option) {
    setOption(option);

    if (option === "Outros") {
      setShowOthers(true);
    } else {
      setShowOthers(false);
    }
  }

  function handleRegister() {
    async function register() {

      var optionSelected = '';

      if (showOthers) {
        optionSelected = otherText;
      } else {
        optionSelected = option;
      }

      if (name === '' || cpf === '' || phone === '' || optionSelected === '' || nameRh === '' || emailRh === '' || phoneRh === '') {
        console.log("Os campos não podem estar vazios!")

        setOpenPopUp(true);
        setPopUpTitle('Atenção!');
        setPopUpMessage('Os campos do formulário não podem estar vazios.');

      } else {
        try {

          setOpenPopUp(true);
          setPopUpTitle('Aguarde...');
          setPopUpMessage('Estamos recebendo seus dados!');

          let data = {
            "nome": name,
            "cpf": cpf,
            "celular": phone,
            "email": email,
            "motivo": optionSelected,
            "nomeRh": nameRh,
            "emailRh": emailRh,
            "telefoneRh": phoneRh,
            "prazo": deadline + 'x',
            "valor": 'R$ ' + valueWithDot + ',00',
          };

          const response = await api.post('/Clientes', data)

          console.log(response)

          if (response === '') {
            setOpenPopUp(true);
            setPopUpTitle('Ops, ocorreu um erro!');
            setPopUpMessage('Estamos passando por instabilidades, tente novamente em alguns instantes.');

            var optionInputs = document.querySelectorAll('input[name=helpOption');
  
            for (let i = 0; i < optionInputs.length; i++) {
              if (optionInputs[i].checked === true) {
                optionInputs[i].checked = false;
              }
            }
    
            setName('');
            setCpf('');
            setPhone('');
            setEmail('');
            setOption('');
            setShowOthers(false);
            setOtherText('');
            setNameRh('');
            setEmailRh('');
            setPhoneRh('');
            setDeadline(6);
            setValue(1000);
            setValueWithDot('1.000');
          } else {
            setOpenPopUp(true);
            setPopUpTitle('Agradecemos o envio de seus dados!');
            setPopUpMessage('Entreremos em contato com a sua empresa para em breve te ajudarmos a realizar seu sonho.');

            var optionSelection = document.querySelectorAll('input[name=helpOption');
  
            for (let i = 0; i < optionSelection.length; i++) {
              if (optionSelection[i].checked === true) {
                optionSelection[i].checked = false;
              }
            }
    
            setName('');
            setCpf('');
            setPhone('');
            setEmail('');
            setOption('');
            setShowOthers(false);
            setOtherText('');
            setNameRh('');
            setEmailRh('');
            setPhoneRh('');
            setDeadline(6);
            setValue(1000);
            setValueWithDot('1.000');
          }
  
        } catch (error) {
          setOpenPopUp(true);
          setPopUpTitle('Ops, ocorreu um erro!');
          setPopUpMessage('Estamos passando por instabilidades, tente novamente em alguns instantes.');
        }
      }
    }
    
    register();

  }

  function insertDotInValue(selectedValue) {
    if (selectedValue === 1000) {
      selectedValue = '1000'
      let newValue = selectedValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      setValueWithDot(newValue);
    } else {
      let newValue = selectedValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      setValueWithDot(newValue);
    }
  }

  function closePopUp(condition) {
    if (condition) {
      setOpenPopUp(false);
    }
  }

  function handleValueChange(event, newValue) {
    setValue(newValue)
    insertDotInValue(newValue)
  }

  function handleDeadline(event, newDeadline) {
    setDeadline(newDeadline)
  }

  return(
    <div id="simulation">
      <div className="container">
        <div className="form-content">
          <h1 className="title">Cadastre-se agora!</h1>
          <p className="subtitle">Cadastre-se e dê o <strong>primeiro passo</strong><br/>para ter <strong>acesso ao seu crédito</strong></p>

          <div className="form-control">
            <input type="text" name="Nome" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nome"/>
          </div>
          <div className="form-control">
            <InputMask mask="999.999.999-99" type="text" name="CPF" id="cpf" value={cpf} onChange={(e) => setCpf(e.target.value)} placeholder="CPF"/>
          </div>
          <div className="form-control">
            <InputMask mask="(99) 99999-9999" type="phone" name="Celular" value={phone} onChange={(e) => setPhone(e.target.value)} id="celular" placeholder="Celular"/>
          </div>
          <div className="form-control">
            <input type="email" name="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} id="email" placeholder="E-mail"/>
          </div>
          <div className="form-control">
            <div className="min-max">
              <span>6 Meses</span>
              <span>36 Meses</span>
            </div>
            <ThemeProvider theme={muiTheme}>
              <Slider
                value={deadline}
                name="Prazo"
                min={6}
                max={36}
                step={1}
                onChange={handleDeadline}
              />
            </ThemeProvider>
            <div className="value-result">
              <p>Prazo: <span>{deadline}</span> Meses</p>
            </div>
          </div>
          <div className="form-control">
            <div className="min-max">
              <span>R$1.000</span>
              <span>R$50.000</span>
            </div>
            <div className="slider-content">
            <ThemeProvider theme={muiTheme}>
              <Slider
                value={value}
                name="Valor"
                min={1000}
                max={50000}
                step={500}
                onChange={handleValueChange}
              />
            </ThemeProvider>
            </div>
            <div className="value-result">
              <p>Valor: <span>R${valueWithDot}</span></p>
            </div>
          </div>

          <p className="subtitle">O que podemos te ajudar a realizar?</p>

          <div className="radio-group">
            <label htmlFor="payDebts">
              <input type="radio" name="helpOption" value="Pagar dívidas" id="payDebts" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Pagar dívidas</div>
            </label>

            <label htmlFor="acquisitionOfGoods">
              <input type="radio" name="helpOption" value="Aquisição de Bens" id="acquisitionOfGoods" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Aquisição de Bens</div>
            </label>

            <label htmlFor="coverExpenses">
              <input type="radio" name="helpOption" value="Cobrir Despesas" id="coverExpenses" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Cobrir Despesas</div>
            </label>

            <label htmlFor="reformTheHouse">
              <input type="radio" name="helpOption" value="Reformar a Casa" id="reformTheHouse" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Reformar a Casa</div>
            </label>

            <label htmlFor="vacations">
              <input type="radio" name="helpOption" value="Férias" id="vacations" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Férias</div>
            </label>

            <label htmlFor="others">
              <input type="radio" name="helpOption" value="Outros" id="others" onClick={(e) => handleOption(e.target.value)}/>
              <div className="content">Outros</div>
            </label>
          </div>

          {showOthers ? <div className="form-control">
            <input type="text" placeholder="Informe aqui qual o outro tipo de aquisição" value={otherText} onChange={(e) => setOtherText(e.target.value)}/>
          </div> : ''}

          <p className="subtitle">Dados da sua empresa</p>

          <div className="form-control">
            <input type="text" name="Nome contato do RH" value={nameRh} onChange={(e) => setNameRh(e.target.value)} id="nameRh" placeholder="Nome do contato do RH"/>
          </div>
          <div className="form-control">
            <input type="email" name="E-mail de contato do RH" value={emailRh} onChange={(e) => setEmailRh(e.target.value)} id="emailRh" placeholder="E-mail do contato do RH"/>
          </div>
          <div className="form-control">
            <InputMask mask="(99) 99999-9999" type="phone" name="Telefone" value={phoneRh} onChange={(e) => setPhoneRh(e.target.value)} id="phoneRh" placeholder="Telefone"/>
          </div>

          <div className="btn-content">
            <button className="btn-send" onClick={handleRegister}>Continuar</button>
          </div>
        </div>
      </div>

      <PopUp
        open={openPopUp}
        title={popUpTitle}
        message={popUpMessage}
        closePopUp={closePopUp}
      />
    </div>
  )
}