import React, { useEffect, useState } from 'react';

// Styles 
import './Hero.css';

// Image
import Detail from '../../assets/images/detail.png';

export default function Hero(props) {
  const [deviceWidthValue, setDeviceWithValue] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", function() {
      setDeviceWithValue(window.innerWidth);
    })
  }, [])

  return(
    <div id="hero" style={deviceWidthValue <= 992 ? {background: `url(${props.image})`} : {}}> 
      <div className="mask"></div>
      <div className="container">
        <div className="hero-wrapper">
          <img className="hero-detail" src={Detail} alt=""/>
          <div className="hero-content">
            <h4 className="hero-subtitle">{props.subtitle}</h4>
            <h1 className="hero-title">{props.title}</h1>
            <p className="hero-text">{props.text}</p>
          </div>
          <div className="hero-image">
            <img src={props.image} style={{left: `${props.imgLeft}`}} alt={props.subtitle}/>
          </div>
        </div>
      </div>
    </div>
  )
}