import React, { useEffect, useState } from 'react';

// Styles
import './Help.css';

// Components
import Faq from '../../components/Faq'; 

// Images
import helpBackground from '../../assets/images/help-background.jpg';
import searchIcon from '../../assets/icons/loupe.png';

export default function Help() {
  const faq = [
    {
      "question": "O que é empréstimo consignado privado?",
      "answer": "É uma modalidade de crédito regulamentada pela Lei Federal 10.820/2003 em que as parcelas não ultrapassam 30% do salário líquido e são descontadas no holerite do colaborador."
    },
    {
      "question": "Por que é necessária a parceria com minha empresa?",
      "answer": "Porque ela vai ser a ponte entre você e a Monee. Como as parcelas são descontadas da folha de pagamento, sua empresa deve permitir e realizar este débito."
    },
    {
      "question": "PRECISO PAGAR ALGUMA TAXA OU VALOR ANTECIPADO PARA TER ACESSO AO CRÉDITO CONSIGNADO MONEE?",
      "answer": "Não. As taxas aplicadas na liberação do crédito estão embutidas nas parcelas. Não pedimos nenhum valor a mais."
    },
    {
      "question": "QUEM PODE CONTRATAR O EMPRÉSTIMO CONSIGNADO MONEE?",
      "answer": "Apenas colaboradores contratados por empresas privadas com carteira assinada (CLT)."
    },
    {
      "question": "QUANTO TEMPO O EMPRÉSTIMO DEMORA PARA CAIR NA MINHA CONTA?",
      "answer": "Após a aprovação, o valor será liberado em até 24 horas."
    },
    {
      "question": "POSSO FAZER EMPRÉSTIMO EM NOME DE TERCEIROS?",
      "answer": "Não. O empréstimo é concedido somente aos colaboradores de empresas conveniadas  que  solicitaram o empréstimo em seu próprio nome."
    },
    {
      "question": "QUANDO VALE A PENA CONTRATAR O EMPRÉSTIMO CONSIGNADO?",
      "answer": "Quando você quiser antecipar um sonho, como dar entrada no ﬁnanciamento da casa própria, reformar sua casa, viajar, comprar um celular ou um computador. Ou ainda, substituir dívidas mais caras, como rotativo do cartão de crédito, cheque especial e empréstimo pessoal."
    },
    {
      "question": "SOU NEGATIVADO, POSSO PEGAR UM EMPRÉSTIMO NA MONEE MESMO ASSIM?",
      "answer": "Sim. A Monee oferece acesso ao empréstimo consignado mesmo para negativados. Assim, é possível quitar suas dívidas com taxas de juros mais justas."
    },
    {
      "question": "POSSO PEDIR DOIS EMPRÉSTIMOS CONSIGNADOS DE UMA VEZ?",
      "answer": "Não. Somente um por vez. Porém, à medida em que você estiver próximo da quitação total da dívida, um novo valor pode ser liberado para a realização de novos sonhos."
    },
    {
      "question": "COMO FAÇO PARA CONTRATAR?",
      "answer": "Se sua empresa já for parceira da Monee, você só precisa falar com o RH de sua empresa ou com a gente. Se não, você pode informar o contato do RH da sua empresa através do nosso site ou telefone e nós propomos a parceria para disponibilizar o benefício pra você e seus colegas de trabalho."
    },
    {
      "question": "COMO SEI QUE MEU EMPRÉSTIMO  FOI APROVADO?",
      "answer": "Por e-mail, WhatsApp ou telefone, por isso, na hora do cadastro, informe e-mail e telefone que você mais usa. Você também poderá conferir a situação do seu empréstimo consignado na nossa plataforma, com seu e-mail e senha."
    },
    {
      "question": "O QUE ACONTECE SE EU NÃO PAGAR AS PARCELAS DO MEU EMPRÉSTIMO APÓS A RESCISÃO?",
      "answer": "A cobrança das parcelas em aberto é feita por boleto bancário, enviado mensalmente para o e-mail. Recomendamos o pagamento o quanto antes pois, em caso de inadimplência, há a possibilidade de negativação ou ajuizamento, dependendo do número de dias em atraso."
    },
    {
      "question": "FUI DESLIGADO DA EMPRESA E AGORA?",
      "answer": "Se isso acontecer, 30% das suas verbas rescisórias serão descontadas para o pagamento da dívida. Se não for suﬁciente para quitar, você receberá os boletos por e-mail para continuar o pagamento até a quitação do contrato."
    },
    {
      "question": "AS PARCELAS ERAM DESCONTADAS DO MEU SALÁRIO. COMO CONTINUO PAGANDO JÁ QUE NÃO TENHO MAIS VÍNCULO COM A EMPRESA?",
      "answer": "Depois da rescisão, o pagamento das parcelas passa a ser feito por boletos bancários enviados para o seu e-mail mensalmente, pelo menos, 7 dias antes da data de vencimento. Caso tenha dúvidas ou precise de ajuda para entender os próximos passos do seu contrato, pode escrever pra gente pelo atendimento@monee.com."
    },
  ]
  const [searchContent, setSearchContent] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  useEffect(() => {
    setFilteredQuestions(faq);
  }, [])

  function handleSearch(searchResult) {
    setFilteredQuestions(faq.filter(search => {
      return search.question.indexOf(searchResult) !== -1 || search.answer.indexOf(searchResult) !== -1;
    }))
  }

  return(
    <div id="help" >
      <div className="form-wrapper" style={{background: `url(${helpBackground})`}}>
        <div className="mask"></div>
        <div className="container">
          <form action="/ajuda.php" className="form-content">
            <h2 className="title">
              Olá, como podemos<br/><span>te ajudar agora?</span>
            </h2>

            <div className="form-control">
              <input type="text" name="name" placeholder="Nome Sobrenome"/>
            </div>
            <div className="form-control">
              <input type="email" name="email" placeholder="E-mail"/>
            </div>
            <div className="form-control">
              <input type="text" name="subject" placeholder="Assunto"/>
            </div>
            <div className="form-control">
              <textarea name="message" id="" cols="30" rows="8" placeholder="Mensagem"></textarea>
            </div>
            <button className="btn-send">Enviar mensagem</button>
          </form>
        </div>
      </div>
      <div className="search-wrapper">
        <div className="container">
          <div className="search-content">
            <h2 className="title">Digite aqui a palavra-chave</h2>
            <div className="search-input">
              <input type="text" name="search" value={searchContent} onChange={(e) => {setSearchContent(e.target.value);  handleSearch(searchContent)}}/>
              <img className="icon-search" src={searchIcon} alt="Procurar"/>
            </div>  
          </div>
        </div>
      </div>
      <div className="faq-wrapper">
        <div className="container">
          <h4 className="subtitle">Perguntas Frequentes</h4>
          <h2 className="title">Consignado Privado Monee</h2>

          <div className="faq-container">
            {filteredQuestions.map((item, index) => {
              return(
                <Faq key={index} id={index} question={item.question} answer={item.answer}/>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}