import React from 'react';

// Images
import detail from '../../assets/images/detail.png';

// Style
import './Banner.css';

export default function Banner(props) {
  return(
    <div id="banner">
      <div className="banner-wrapper" style={{background: `url(${props.bannerImage})`}}>
        <div className="mask"></div>
        <div className="container">
          <div className="banner-content">
            <h1 className="title">{props.title}</h1>
            <p className="subtitle">{props.subtitle}<br/><span>{props.hashtag}</span></p>
          </div>
          <img src={detail} alt="Monee ícone" className="detail"/>
          <span className="extra">{props.extra}</span>
        </div>
      </div>
    </div>
  )
}