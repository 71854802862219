import React from 'react';

// Components
import Hero from '../../components/Hero';

// Styles
import './AboutUs.css';

// Images
import aboutUsBackground from '../../assets/images/sobre-background.jpg';
import {ReactComponent as MissionIcon} from '../../assets/icons/au-missao.svg';
import {ReactComponent as ValuesIcon} from '../../assets/icons/au-valores.svg';
import {ReactComponent as DutiesIcon} from '../../assets/icons/au-deveres.svg';

export default function AboutUs() {
  return (
    <div id="aboutUs">
      <Hero
        title="Sobre a Monee"
        subtitle="Quem Somos"
        text="É uma plataforma online focada no acesso ao crédito consignado privado que nasceu para que as pessoas possam realizar o que elas têm vontade. Para nós, viabilizar o acesso ao crédito tem como base uma filosofia pautada em escutar, entender e criar junto com quem deposita confiança na gente. Afinal de contas, ter independência e controle sobre as próprias escolhas agora faz a vida andar pra frente"
        image={aboutUsBackground}
        imgLeft="-150px"
      />

      <div className="resume-wrapper">
        <div className="container">
          <div className="resume">
            <div className="content">
              <MissionIcon className="icon"/>
              <h3 className="title">Missão</h3>
              <p className="text">Viabilizar o acesso ao crédito, e consequentemente, as realizacoes de vida das pessoas.</p>
            </div>
            <div className="content">
              <ValuesIcon className="icon"/>
              <h3 className="title">Valores</h3>
              <p className="text">Integridade, Ética, Comunicação aberta e honesta, Respeito, Humildade e Simplicidade.</p>
            </div>
            <div className="content">
              <DutiesIcon className="icon"/>
              <h3 className="title">Deveres</h3>
              <p className="text">Integridade, Ética, Comunicação aberta e honesta, Respeito, Humildade e Simplicidade.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-us-wrapper">
        <div className="container">
          <div className="about-us-content">
            <h2 className="title">Não Somos um banco!</h2>
            <p className="text">
            Nós somos uma fintech. Ou seja, um tipo de startup que vêm revolucionando o mercado financeiro no país ao oferecer acesso ao crédito. No nosso caso, viabilizamos empréstimos consignados privados para que as empresas possam oferecer aos seus colaboradores. Esse serviço conta com vantagens como agilidade, segurança e menores taxas. Queremos escutar você.
            </p>
            <span>Vamos bater um papo?</span>
          </div>

          <div className="contact">
            <span>Fale com a Monee</span>
            <a href="https://api.whatsapp.com/send?phone=5562992474584" className="btn-contact" target="_blank" rel="noopener noreferrer">WhatsApp</a>
          </div>
        </div>
      </div>
    </div>
  )
}