import React from 'react';

// Styles
import './UseTerms.css';

export default function UseTerms() {
  return(
    <div id="useTerms">
      <div className="divisor"></div>
      <div className="container">
        <h1 className="title">Termos de uso</h1>

        <p className="text">
        <p>O monee.com.br (&ldquo;Website&rdquo;) &eacute; pertencente e operado pela Monee Servi&ccedil;os Financeiras Ltda. (&ldquo;Monee&rdquo;), uma sociedade limitada registrada sob o CNPJ 36.251.922/0001-19, com sede na Rodovia BR-153, Quadra 71A, Lote 6E, Sala 2 &ndash; Vila Bras&iacute;lia, Aparecida de Goi&acirc;nia - GO, 74.911-410.</p>
<p>Leia este documento para compreender as regras que regem o uso do site e servi&ccedil;os da Monee. Se tiver d&uacute;vidas, perguntas ou coment&aacute;rios sobre estes Termos de Uso ou os Servi&ccedil;os, entre em contato conosco: ajuda@monee.com.br.</p>
<p>Estes Termos de Uso (&ldquo;Termos&rdquo;) incluem das Condi&ccedil;&otilde;es Gerais e Condi&ccedil;&otilde;es Adicionais e, em conjunto com os demais contratos e a nossa Pol&iacute;tica de Privacidade, estabelecem os termos e condi&ccedil;&otilde;es aplic&aacute;veis &agrave; utiliza&ccedil;&atilde;o de nosso Website.</p>
<p><strong>AVISO: LEIA ESTES TERMOS CUIDADOSAMENTE ANTES DE CONTINUAR UTILIZANDO NOSSO WEBSITE OU DE UTILIZAR QUAISQUER DE NOSSOS SERVI&Ccedil;OS.</strong>&nbsp;Eles cobrem informa&ccedil;&otilde;es importantes sobre os Servi&ccedil;os prestados e altera&ccedil;&otilde;es futuras destes Termos. &Eacute; muito importante que Voc&ecirc; leia e compreenda estas regras, bem como outras regras que possam ser aplic&aacute;veis, incluindo aquelas advindas da Lei n&ordm; 8.078/1990 (&ldquo;C&oacute;digo de Defesa do Consumidor&rdquo;).</p>
<p>Caso n&atilde;o concorde integralmente com os Termos de Uso, Pol&iacute;tica de Privacidade ou outras normas aplic&aacute;veis ao uso dos servi&ccedil;os e do Website, Voc&ecirc; dever&aacute; interromper o uso do Website e se abster de utilizar nossos servi&ccedil;os.</p>
<p><strong>Como a Monee atua</strong></p>
<p>N&oacute;s n&atilde;o somos uma institui&ccedil;&atilde;o financeira, companhia de seguros, operadora de meios de pagamento ou bandeira de cart&atilde;o de cr&eacute;dito.&nbsp;N&oacute;s prestamos servi&ccedil;os de correspondente banc&aacute;rio, oferecendo servi&ccedil;os de cr&eacute;dito privado consignado as colaboradores de empresas conveniadas. Atuamos como um meio online para que Voc&ecirc; e outros usu&aacute;rios encontrem com maior facilidade os produtos e servi&ccedil;os ofertados pela(s) nossa(s) Institui&ccedil;&atilde;o(&otilde;es) Parceira(s) (&ldquo;Servi&ccedil;os&rdquo;).</p>
<p>No endere&ccedil;o a seguir, Voc&ecirc; poder&aacute; verificar nossas&nbsp;Institui&ccedil;&otilde;es Parceiras:&nbsp;<u>Verifique</u>&nbsp;</p>
<p><strong>Utiliza&ccedil;&atilde;o do nosso Website</strong></p>
<p>&Eacute; poss&iacute;vel navegar pela maior parte do Website sem estar registrado na conta de usu&aacute;rio, caso em que ser&atilde;o aplic&aacute;veis as Condi&ccedil;&otilde;es Gerais do Visitante (Parte I abaixo). Por&eacute;m, para acessar determinadas &aacute;reas e funcionalidades do Website, Voc&ecirc; dever&aacute; confirmar expressamente sua anu&ecirc;ncia com os Termos e Condi&ccedil;&otilde;es Adicionais (Parte II abaixo) antes do seu primeiro acesso.</p>
<p>Ao utilizar o nosso Website, Voc&ecirc; declara que possui capacidade civil e que &eacute; maior de 18 anos.</p>
<p>N&atilde;o solicitamos nem coletamos intencionalmente dados de identifica&ccedil;&atilde;o pessoal de pessoas menores de 18 anos. Se voc&ecirc; possuir menos de 18 anos, n&atilde;o tente se registrar nos Servi&ccedil;os nem nos envie quaisquer dados pessoais. Se acreditar que uma pessoa menor de 18 anos pode ter nos fornecido seus Dados Pessoais, entre em contato conosco em <a href="mailto:ajuda@monee.com.br">ajuda@monee.com.br</a><u>.</u></p>
<p><strong>Estes Termos podem ser alterados?</strong></p>
<p>Sim, estamos constantemente buscando melhorar os nossos Servi&ccedil;os e, assim, pode ser necess&aacute;rio alterar estes Termos juntamente com os Servi&ccedil;os. Reservamo-nos o direito de alterar os Termos a qualquer momento, mas se os alterarmos de forma substancial, levaremos isso a sua aten&ccedil;&atilde;o ao colocar um aviso no Website, ao enviar-lhe um e-mail e/ou por outros meios. Sugerimos fortemente que Voc&ecirc; consulte eventuais atualiza&ccedil;&otilde;es de tempos em tempos.</p>
<p>Se n&atilde;o concordar com os novos Termos, voc&ecirc; poder&aacute; rejeit&aacute;-los e deixar de utilizar o Website ou os Servi&ccedil;os. A &uacute;ltima vers&atilde;o destes Termos estar&aacute; dispon&iacute;vel no Website em <a href="http://www.monee.com.br/termos-condicoes">www.monee.com.br/termos-condicoes</a>. Salientamos que tais altera&ccedil;&otilde;es ser&atilde;o aplic&aacute;veis aos nossos servi&ccedil;os desde o momento em que forem disponibilizadas no Website.&nbsp;</p>
<p>Algumas condi&ccedil;&otilde;es espec&iacute;ficas para certos servi&ccedil;os podem substituir ou complementar estes Termos, conforme alertado nos respectivos avisos legais dispon&iacute;veis em outras &aacute;reas do Website.</p>
<p><strong>Privacidade e Seguran&ccedil;a.</strong></p>
<p>A Monee se compromete com a privacidade dos Usu&aacute;rios nos termos de sua pol&iacute;tica de privacidade, dispon&iacute;vel em&nbsp;<a href="https://www.monee.com.br/politica-privacidade"><u>https://www.monee.com.br/</u><u>politica-privacidade</u></a>&nbsp;(&ldquo;Pol&iacute;tica de Privacidade&rdquo;), que &eacute; parte integrante destes Termos.</p>
<p>Ao acessar nosso Website ou contratar nossos Servi&ccedil;os, Voc&ecirc; declara estar ciente de que N&oacute;s poderemos coletar, armazenar e compartilhar informa&ccedil;&otilde;es sobre Voc&ecirc; para as finalidades informadas na Pol&iacute;tica de Privacidade.</p>
<p>Voc&ecirc; n&atilde;o deve compartilhar a sua conta ou senha com ningu&eacute;m e voc&ecirc; deve mant&ecirc;-las em seguran&ccedil;a. Voc&ecirc; &eacute; respons&aacute;vel por qualquer atividade associada &agrave; sua conta. Voc&ecirc; concorda em notificar imediatamente a Monee no caso de qualquer uso n&atilde;o autorizado da sua conta.</p>
<p><strong>Condi&ccedil;&otilde;es Gerais.</strong></p>
<p>Dependendo do Servi&ccedil;o contratado por Voc&ecirc; poder&atilde;o ser aplicadas diferentes condi&ccedil;&otilde;es comerciais (&ldquo;Condi&ccedil;&otilde;es Comerciais&rdquo;). Tais Condi&ccedil;&otilde;es Comerciais dever&atilde;o ser verificadas por Voc&ecirc; antes da contrata&ccedil;&atilde;o do Servi&ccedil;o.</p>
<p>As ofertas e servi&ccedil;os divulgados por Institui&ccedil;&otilde;es Parceiras, anunciantes e outros parceiros no nosso Website n&atilde;o s&atilde;o de responsabilidade da Monee nem tem rela&ccedil;&atilde;o com quaisquer ofertas ou servi&ccedil;os da Monee. Caso Voc&ecirc; contratoe servi&ccedil;os dessas Institui&ccedil;&otilde;es Parceiras, anunciantes ou outros parceiros, eles poder&atilde;o cobrar taxas de Voc&ecirc; conforme seus pr&oacute;prios contratos e termos de uso. A Monee n&atilde;o tem qualquer responsabilidade ou envolvimento relativo a tais contrata&ccedil;&otilde;es, mesmo nos casos em que a Monee tenha recevido remunera&ccedil;&atilde;o das Institui&ccedil;&otilde;es Parceiras, anunciantes e outros parceiros para que eles sejam listados no nosso Website..Ao utilizar o Website, Voc&ecirc; expressamente reconhece que N&oacute;s:</p>
<ul type="disc">
<li>N&atilde;o somos respons&aacute;veis pelo cumprimento das normas e requisitos legais por parte das Institui&ccedil;&otilde;es Parceiras, anunciantes e outros parceiros indicados no Website. Voc&ecirc; &eacute; o &uacute;nico respons&aacute;vel pela verifica&ccedil;&atilde;o pr&eacute;via da regularidade e idoneidade dessas institui&ccedil;&otilde;es.</li>
<li>N&atilde;o atuamos e nem substitu&iacute;mos consultores e assessores especializados na contrata&ccedil;&atilde;o de servi&ccedil;os financeiros, mesmo que tais servi&ccedil;os sejam indicados no Website.</li>
<li>N&atilde;o somos respons&aacute;veis pelas decis&otilde;es financeiras que Voc&ecirc; possa vir a fazer por meio do Website. S&oacute; Voc&ecirc; ser&aacute; respons&aacute;vel por elas.</li>
<li>N&atilde;o garantimos que as Institui&ccedil;&otilde;es Parceiras cumpram suas obriga&ccedil;&otilde;es contratual e legalmente estabelecidas.</li>
<li>N&atilde;o asseguramos a continuidade dos servi&ccedil;os ou a manuten&ccedil;&atilde;o das ofertas e cota&ccedil;&otilde;es, sem preju&iacute;zo da boa conclus&atilde;o dos servi&ccedil;os j&aacute; iniciados.</li>
<li>N&atilde;o atuamos em seu nome, em nome das Institui&ccedil;&otilde;es Parceiras ou de quaisquer terceiros, apenas disponibilizando uma plataforma para facilitar a contrata&ccedil;&atilde;o de produtos financeiros oferecidos pelas Institui&ccedil;&otilde;es Parceiras.</li>
<li>N&atilde;o prometemos qualquer forma de rentabilidade ou de previsibilidade de taxas, tarifas, ganhos, lucros, perdas ou condi&ccedil;&otilde;es de mercado.</li>
</ul>
<p>N&atilde;o respondemos pelo uso de marcas, direitos autorais ou outros direitos pelas Institui&ccedil;&otilde;es Parceiras, anunciantes e outros parceiros, nem garantimos que sejam os devidos titulares desses direitos. Se voc&ecirc; acreditar ser o titular de &nbsp;marcas, direitos autorais ou outros direitos usados ou anunciados pelas Institui&ccedil;&otilde;es Parceiras, anunciantes e outros parceiros por meio do nosso Website, entre em contato conosco em&nbsp;<a href="mailto:ajuda@monee.com.br">ajuda@monee.com.br</a>&nbsp;para direcionarmos sua reclama&ccedil;&atilde;o.</p>
<h2><strong>Demais Condi&ccedil;&otilde;es dos Termos de Uso</strong></h2>
<p>N&oacute;s somos uma empresa brasileira e o Website e seus servi&ccedil;os s&atilde;o criados e mantidos em fiel cumprimento &agrave;s leis brasileiras e demais tratados que s&atilde;o incorporados ao ordenamento jur&iacute;dico brasileiro. Caso Voc&ecirc; esteja usufruindo de nossos servi&ccedil;os fora do Brasil, Voc&ecirc; ser&aacute; respons&aacute;vel pelo cumprimento das leis locais, na medida em que forem aplic&aacute;veis.</p>
<p>Caso N&oacute;s deixemos de exercer qualquer direito previsto nestes Termos, isto n&atilde;o dever&aacute; ser interpretado como uma ren&uacute;ncia, abdica&ccedil;&atilde;o ou revoga&ccedil;&atilde;o de disposi&ccedil;&atilde;o constante destes Termos.</p>
<p>Caso qualquer disposi&ccedil;&atilde;o ou item destes Termos seja considerado inaplic&aacute;vel, nula, ilegal ou de qualquer outra forma ineficaz, as demais disposi&ccedil;&otilde;es e itens destes Termos continuar&atilde;o em pleno vigor.</p>
<p>Todos os itens destes Termos ser&atilde;o regidos pelas leis vigentes da Rep&uacute;blica Federativa do Brasil. Para dirimir quaisquer controv&eacute;rsias &eacute; eleito o Foro da Cidade de Goi&acirc;nia no Estado de Goi&aacute;s.</p>
<h2><strong>Institui&ccedil;&otilde;es Parceiras</strong></h2>
<p>A Monee &eacute; uma plataforma digital que atua como correspondente Banc&aacute;rio para facilitar o processo de contrata&ccedil;&atilde;o de empr&eacute;stimos. Somos correspondentes banc&aacute;rio da seguinte institui&ccedil;&atilde;o: BNP Money Plus Sociedade de Credito Direto S.A. (CNPJ 34.337.707/0001-00) nos termos do artigo 2&ordm;, da Resolu&ccedil;&atilde;o CMN n&ordm; 3.954, de 24 de fevereiro de 2011.</p>
        </p>
      </div>
    </div>
  )
}