import React, { useEffect } from 'react';
import $ from 'jquery';

// Components
import Hero from '../../components/Hero';

// Styles
import './ToCompany.css';

// Images
import toCompanyBackground from '../../assets/images/para-empresas.jpg';
import BenefityIcon from '../../assets/icons/pe-beneficios.png';
import {ReactComponent as CheckIcon} from '../../assets/icons/pe-check.svg';

export default function ToCompany() {

  useEffect(() => {
    $('.form-section .title').on('click', function() {
      $('.form-section form').slideToggle();
    })
  }, [])

  return(
    <div id="toCompany">
      <Hero
        title="Empréstimo Consignado privado"
        subtitle="Para Empresas"
        text="O caminho mais fácil para tranquilizar e deixar os seus colaboradores mais felizes. Na Monee isso é possível. Por que não agora?"
        image={toCompanyBackground}
      />

      <div className="resume">
        <div className="container">
          <h2 className="title">O Nosso diferencial</h2>
          <h5 className="subtitle">Taxas de juros mais baixas para os seus colaboradores. Pois, menos preocupados com questões financerias, com mais qualidade de vida e satisfeitos, eles geram mais produtividade e menos rotatividade para a sua empresa.</h5>
        </div>
      </div>

      <div className="container">
        <div className="card-content">
          <div className="first-card">
            <img src={BenefityIcon} className="icon" alt="Ícone benefícios"/>
            <h3 className="title">
              Benefícios para a empresa e para o RH
            </h3>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Cria consciência</h4>
            <p className="text">
              Melhora a vida financeira do colaborador e, como consequência, diminui os pedidos de adiantamento de salário, décimo terceiro e férias.
            </p>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Facilidade</h4>
            <p className="text">
              A plataforma é 100% online mas o atendimento é humanizado: tem sempre um atendente da Monee para falar com seu RH. Além disso, todas as solicitações são aprovadas com rapidez e não precisa fazer o cálculo do valor a ser descontado do colaborador.
            </p>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Diminui a rotatividade</h4>
            <p className="text">
              É uma forma eficiente de diminuir também o número de funcionários querendo forçar demissão para quitar dividar com dinheiro da rescisão contratual.
            </p>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Condições reais</h4>
            <p className="text">
              Oferece as menores taxas e condições de crédito consignado do mercado.
            </p>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Segurança</h4>
            <p className="text">
              O empréstimo consignado privado significa risco zero para a empresa.
            </p>
          </div>
          <div className="card">
            <CheckIcon className="icon"/>
            <h4 className="title">Educação financeira</h4>
            <p className="text">
              A Monee ajuda a educar os seus colaboradores com palestras, workshops e materiais sobre o assunto.
            </p>
          </div>
        </div>

        <div className="form-section">
          <div className="form-content">
            <h2 className="title">Seja uma empresa parceira</h2> 
            <form action="./contact.php">
              <div className="column-input">
                <div className="row-input">
                  <input type="text" name="company" placeholder="Nome da Empresa"/>
                  <input type="text" name="employee" placeholder="Nome da Funcionário"/>
                </div>
                <div className="row-input">
                  <input type="email" name="companyMail" placeholder="E-mail da Empresa"/>
                </div>
                <div className="row-input">
                  <textarea name="" id="" cols="30" rows="5" placeholder="Mensagem"></textarea>
                </div>
              </div>
              <div className="btn-content">
                <button type="submit" className="btn-submit">ENVIAR A MENSAGEM</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}