import React from 'react';

// Components
import Hero from '../../components/Hero';

// Style
import './ToYou.css';

// Images
import ToYouBackground from '../../assets/images/para-voce.jpg';
import {ReactComponent as FacilitiesIcon} from '../../assets/icons/pv-facilidades.svg';
import {ReactComponent as SolutionsIcon} from '../../assets/icons/pv-solucoes.svg';
import {ReactComponent as SimplicityIcon} from '../../assets/icons/pv-simplicidade.svg';
import {ReactComponent as SecurityIcon} from '../../assets/icons/pv-seguranca.svg';
import {ReactComponent as AgilityIcon} from '../../assets/icons/pv-agilidade.svg';
import {ReactComponent as TranparencyIcon} from '../../assets/icons/pv-transparencia.svg';
import {ReactComponent as ComodityIcon} from '../../assets/icons/pv-comodidade.svg';
import {ReactComponent as CareIcon} from '../../assets/icons/pv-cuidado.svg';

export default function ToYou() {
  return (
    <div id="toYou">
      <Hero 
        title="Empréstimo Consignado privado sem enrolação"
        subtitle="Para você"
        text="A Monee indica o caminho para você fazer o que tem vontade. Porque a vida não espera."
        image={ToYouBackground}
      />

      <div className="resume">
        <div className="container">
          <h2 className="title">Mesmo negativo, você conta com taxas competitivas</h2>
          <h5 className="subtitle">mais baixas que as do empréstimo pessoal, cheque especial e cartão de crédito.</h5>
        </div>
      </div>

      <div className="video-content">
        <iframe src="https://www.youtube.com/embed/KH1jEfRRBmE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>

      <div className="container">
        <div className="cards-content">
          <div className="card">
            <FacilitiesIcon className="icon"/>
            <h3 className="title">Facilidades</h3>
            <p className="text">Taxas mais competitivas em relação aos bancos tradicionais e outras linhas de crédito, e parcelas que você pode pagar.</p>
          </div>       
          <div className="card">
            <SolutionsIcon className="icon"/>
            <h3 className="title">Soluções</h3>
            <p className="text">A opção ideal para resolver problemas financeiros que envolvam taxas de juros muito elevadas.</p>
          </div>       
          <div className="card">
            <SimplicityIcon className="icon"/>
            <h3 className="title">Simplicidade</h3>
            <p className="text">Nossos processos são descomplicados, sem burocracias.</p>
          </div>       
          <div className="card">
            <SecurityIcon className="icon"/>
            <h3 className="title">Segurança</h3>
            <p className="text">Com juros e valores pré-fixados você não tem surpresas no fim do mês.</p>
          </div>       
          <div className="card">
            <AgilityIcon className="icon"/>
            <h3 className="title">Agilidade</h3>
            <p className="text">Liberação de crédito em 24 horas em qualquer banco.</p>
          </div>       
          <div className="card">
            <TranparencyIcon className="icon"/>
            <h3 className="title">Transparência</h3>
            <p className="text">Empréstimo sem exigência de contrapartida ou compra de outros produtos da nossa fintech.</p>
          </div>       
          <div className="card">
            <ComodityIcon className="icon"/>
            <h3 className="title">Comodidade</h3>
            <p className="text">Processo totalmente online, ou seja, não precisa perder tempo com locomoção nem ir ao banco para pagar a parcela no dia do vencimento.</p>
          </div>       
          <div className="card">
            <CareIcon className="icon"/>
            <h3 className="title">Cuidado</h3>
            <p className="text">A Monee está pronta para ajudar, inclusive tirar você que está negativo do aperto e resolver a sua vida financeira. Conte com a gente!</p>
          </div>       
        </div>
      </div>
    </div>
  )
}