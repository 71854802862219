import React from 'react';

// Styles
import './EnterNow.css';

export default function EnterNow() {
  return (
    <div id="enterNow">
      <div className="container">
        <h1 className="title">Olá, entre e saiba tudo sobre o consignado<br/>privado Monee. Vamos lá?</h1>
        <div className="options-content">
          <div className="option">
            <div className="header">
              <h5 className="subtitle">Não sou parceiro</h5>
              <h2 className="title">Indicar minha empresa</h2>
            </div>
            <a href="/simulacao" className="btn-access">Indicar</a>
          </div>
          <div className="option">
           <div className="header">
              <h5 className="subtitle">Já sou parceira</h5>
              <h2 className="title">Empresa</h2>
           </div>
            <a href="http://rh.monee.com.br" target="blank" className="btn-access" rel="noopener noreferrer">Acessar</a>
          </div>
          <div className="option">
            <div className="header">
              <h5 className="subtitle">Já sou cadastrado</h5>
              <h2 className="title">Colaborador</h2>
            </div>
            <a href="http://cliente.monee.com.br" target="blank" className="btn-access" rel="noopener noreferrer">Acessar</a>
          </div>
        </div>
      </div>
    </div>
  )
}