import React from 'react';

// Styles
import './Footer.css';

export default function Footer() {
  const year = new Date().getFullYear();

  return(
    <div className="footer-wrapper">
      <div className="container">
        <footer>
          <div className="footer-column">
            <h3>Sobre Nós</h3>
            <p>É uma plataforma online focada no acesso ao crédito consignado privado que nasceu para que as pessoas possam realizar o que elas têm vontade.</p>
            <h3 style={{marginTop: '40px'}}><a href="/termos-uso">Termos de uso</a></h3>
            <h3 style={{textDecoration: 'underline'}}><a href="/politica-privacidade">Política de Privacidade</a></h3>
          </div>
          <div className="footer-column">
            {/* <h3 style={{marginBottom: 0}}>Endereço</h3>
            <p>Rodovia BR 153 - KM 6,5 QD 71-A Lt-08<br/>Vila Brasília - Aparecida de Goiânia - Goiás</p> */}
            <h3>Redes Sociais</h3>
            <div className="socials">
            <a href="https://www.instagram.com/monee_br/" className="footer-social" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a>
            <a href="https://www.facebook.com/Monee-596110767717942/" className="footer-social" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i> Facebook</a>
            </div>
            <h4>Telefone</h4>
            <div className="phones">
            <a href="https://api.whatsapp.com/send?phone=5562992474584" className="footer-social" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i> +55 (62) 99247-4584</a>
            <a href="tel:6241031248" className="footer-social" target="_blank" rel="noopener noreferrer"><i className="fas fa-phone"></i> +55 (62) 4103-1248</a>
            </div>
          </div>
          <div className="footer-column">
            <h3>Instagram</h3>
            <iframe src="https://lightwidget.com/widgets/c6df0c7b73955a95ba206c4008498007.html" scrolling="no" allowTransparency="true" className="lightwidget-widget" />
          </div>
        </footer>

        <div className="commitments">
          <h3>COMPROMISSO MONEE</h3>
          <p>O site www.monee.com.br pertence e é operado por Monee Serviços de Crédito Ltda. (”Monee”), uma sociedade limitada registrada sob o CNPJ 36.251.922/0001-19, com sede na Rodovia BR-153 Quadra 71A Lote 6E, Município de Aparecida de Goiânia, Estado de Goiás, CEP 74911-410. A Monee não é uma ﬁnanceira: somos um correspondente bancário da BMP MONEY PLUS SOCIEDADE DE CRÉDITO DIRETO S.A. inscrita no CNPJ sob o nº 34.337.707/0001-00. A Monee não cobra quaisquer valores diretamente dos clientes. Somos remunerados apenas pelas instituições ﬁnanceiras parceiras. Caso você receba um e-mail ou seja contatado por alguém se fazendo passar pela e cobrando quaisquer taxas para fechar negócios em nossa plataforma, encerre o contato imediatamente pois você está sendo vítima de uma tentativa de fraude. As taxas de juros do produto de    crédito privado consignado variam de 1,5%, mínimo, a 6,00%, máximo, ao mês. Período de quitação do empréstimo de 3 a 36 meses, podendo ser antecipado a qualquer momento. Exemplo – Empréstimo R$5.000,00 para pagamento em 12 meses – Total de 12 parcelas de R$527,16. Valor total  a pagar igual a R$6.325,92 com uma taxa de juros de 3,50% ao mês (51,11% a.a). A Monee tem total compromisso com simplicidade e transparência. Antes de contratar um produto de crédito privado consignado, será exibido de forma clara: a taxa de juros aplicada, tarifas cobradas, impostos (IOF) e o custo efetivo total (CET). Sabemos que não é fácil entender todos esses valores e em caso de dúvidas, estamos à disposição para esclarecê-los.</p>
        </div>
        
      </div>
      <div className="copyright">
        <div className="container">
          <div className="copyright-content">
            <span>Desenvolvido por <a href="https://pibclub.com" target="_blank" rel="noopener noreferrer">PibClub</a> &copy; {year}</span>
            <div className="social-links">
              <a href="https://www.facebook.com/Monee-596110767717942/" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://api.whatsapp.com/send?phone=5562992474584" target="_blank" rel="noopener noreferrer">WhatsApp</a>
              <a href="https://www.instagram.com/monee_br/" target="_blank" rel="noopener noreferrer">Instagram</a>
              <a href="#" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}