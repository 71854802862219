import React from 'react';

// Styles
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
  return(
    <div id="privacyPolicy">
      <div className="divisor"></div>
      <div className="container">
        <h1 className="title">Política de Privacidade</h1>

        <p className="text">
        <p >Esta Pol&iacute;tica de Privacidade estabelece o compromisso da Monee Servi&ccedil;os de Cr&eacute;dito Ltda. (&ldquo;Monee&rdquo;) com a privacidade dos usu&aacute;rios do website <a href="http://www.monee.com.br/">www.monee.com.br </a>(&ldquo;Website&rdquo;). Voc&ecirc; deve ler e compreender esta Pol&iacute;tica de Privacidade antes de come&ccedil;ar a utilizar o Website. Ao utilizar o Website, Voc&ecirc; automaticamente concorda com todos os termos de nossa Pol&iacute;tica de Privacidade e com os nossos Termos e Condi&ccedil;&otilde;es de Uso.</p>

<p>Esta Pol&iacute;tica de Privacidade pode ser alterada a qualquer momento, sem pr&eacute;vio aviso por parte da Monee. A &uacute;ltima vers&atilde;o desta Pol&iacute;tica sempre estar&aacute; dispon&iacute;vel no Website em <a href="http://www.monee.com.br/politica-privacidade">www.monee.com.br/politica-privacidade, </a>e sugerimos fortemente que Voc&ecirc; con- sulte eventuais atualiza&ccedil;&otilde;es de tempos em tempos. Salientamos que tais altera&ccedil;&otilde;es ser&atilde;o aplic&aacute;veis desde o momento em que forem disponibilizadas no Website.</p>

<p>Nesta Pol&iacute;tica, Voc&ecirc; encontrar&aacute; as informa&ccedil;&otilde;es necess&aacute;rias para entender sobre:</p>

<ul>
	<li>Quais informa&ccedil;&otilde;es coletamos sobre Voc&ecirc;;</li>
	<li>Como coletamos essas informa&ccedil;&otilde;es;</li>
	<li>Como interromper a coleta de informa&ccedil;&otilde;es;</li>
	<li>Como armazenamos e protegemos as informa&ccedil;&otilde;es coletadas;</li>
	<li>Para que usamos essas informa&ccedil;&otilde;es;</li>
	<li>Em quais casos poderemos compartilhar estas informa&ccedil;&otilde;es;</li>
	<li>Quais direitos garantimos a Voc&ecirc;</li>
	<li>Quem somos e como contatar-nos Quais Informa&ccedil;&otilde;es N&oacute;s Coletamos</li>
</ul>

<p>Para aprimorarmos nossos servi&ccedil;os e melhorarmos continuamente a experi&ecirc;ncia de navega&ccedil;&atilde;o, coletamos informa&ccedil;&otilde;es impor- tantes de nossos clientes. As informa&ccedil;&otilde;es que coletamos s&atilde;o as seguintes:</p>

<ul>
	<li>Informa&ccedil;&otilde;es de Cadastro: Ao se cadastrar, coletamos seu nome, e-mail, endere&ccedil;o, telefone de contato, CPF, RG, data de na- scimento e sexo.</li>
	<li>Informa&ccedil;&otilde;es de Renda Pessoal: Quando Voc&ecirc; solicita cota&ccedil;&otilde;es ou propostas, tamb&eacute;m solicitaremos informa&ccedil;&otilde;es ﬁnanceiras relevantes para que possam ser oferecidos produtos ou servi&ccedil;os adequados &agrave;s suas necessidades. Tais informa&ccedil;&otilde;es incluem sua renda, suas despesas, bens de sua propriedade e outros dados do seu patrim&ocirc;nio.</li>
	<li>Informa&ccedil;&otilde;es de Acesso: Coletamos informa&ccedil;&otilde;es e dados sobre a sua experi&ecirc;ncia de navega&ccedil;&atilde;o em nosso Website. Ao longo de sua visita no Website, ser&atilde;o automaticamente coletados o seu Internet Protocol (IP), sua localiza&ccedil;&atilde;o, seu tipo de computador, tipo de celular, tipo de navegador, p&aacute;ginas e servi&ccedil;os acessados, entre outros.</li>
</ul>

<p>A coleta de informa&ccedil;&otilde;es de cadastro e de renda pessoal s&atilde;o essenciais para nosso modelo de neg&oacute;cio e sem a sua coleta as funciona- lidades do Website n&atilde;o poder&atilde;o ser utilizadas.</p>

<p>&nbsp;</p>

<p>Como Coletamos Informa&ccedil;&otilde;es Sobre Voc&ecirc;</p>

<p>N&oacute;s coletamos e armazenamos informa&ccedil;&otilde;es sobre Voc&ecirc; por meio:</p>

<ul>
	<li>Do seu cadastro no Website.</li>
	<li>Das suas solicita&ccedil;&otilde;es de cota&ccedil;&otilde;es e propostas de institui&ccedil;&otilde;es ﬁnanceiras, companhias de seguros, operadoras de meios de pagamento ou bandeira de cart&atilde;o de cr&eacute;dito (&ldquo;Institui&ccedil;&otilde;es Parceiras&rdquo;).</li>
	<li>De Cookies, automaticamente. Cookies s&atilde;o identiﬁca&ccedil;&otilde;es da intera&ccedil;&atilde;o com nosso site ou nossas publicidades que s&atilde;o trans- feridas para o aparelho do cliente, visando reconhec&ecirc;-lo na pr&oacute;xima vez que acessar nosso website. Utilizamos cookies para viabili- zar recursos personalizados como recomenda&ccedil;&otilde;es de infogr&aacute;ﬁcos, artigos, calculadoras, produtos e servi&ccedil;os ﬁnanceiros, bem como para oferecer publicidades e informa&ccedil;&otilde;es adicionais do seu interesse.</li>
</ul>

<p>Caso deseje interromper a coleta de informa&ccedil;&otilde;es por meio de cookies, voc&ecirc; poder&aacute; desabilitar a fun&ccedil;&atilde;o em seu navegador de inter- net, apag&aacute;-los e gerenciar sua utiliza&ccedil;&atilde;o por meio das conﬁgura&ccedil;&otilde;es de seu navegador. No entanto, n&atilde;o &eacute; poss&iacute;vel explorar todos os recursos de navega&ccedil;&atilde;o personalizada oferecidos no Website sem a aceita&ccedil;&atilde;o dos cookies.</p>

<p>Como Armazenamos e Protegemos as Informa&ccedil;&otilde;es Coletadas</p>

<p >Ap&oacute;s coletarmos os dados e informa&ccedil;&otilde;es mencionados nessa Pol&iacute;tica de Privacidade, iremos armazen&aacute;-los sob as mais r&iacute;gidas pr&aacute;ti- cas de seguran&ccedil;a de informa&ccedil;&atilde;o. Nosso banco de dados ter&aacute; seu acesso criteriosamente restringido a apenas alguns funcion&aacute;rios ha- bilitados, que s&atilde;o obrigados por contrato a preservar a conﬁdencialidade de suas informa&ccedil;&otilde;es.</p>

<p>Iremos sempre empregar nossos melhores esfor&ccedil;os para garantir que suas informa&ccedil;&otilde;es ser&atilde;o sempre manipuladas de acordo com o estabelecido nesta Pol&iacute;tica de Privacidade. Ainda assim, Voc&ecirc; deve saber que n&oacute;s n&atilde;o somos respons&aacute;veis pela quebra de segu- ran&ccedil;a devido a extravio ou compartilhamento de senhas. Em tal caso, nenhuma compensa&ccedil;&atilde;o por parte da Monee ser&aacute; devida a Voc&ecirc;.</p>

<p>Para Que Usamos Essas Informa&ccedil;&otilde;es</p>

<p >Todas as informa&ccedil;&otilde;es coletadas pela Monee no Website servem para permitir que prestemos servi&ccedil;os cada vez melhores para o usu&aacute;rio.</p>

<p>N&oacute;s usamos as informa&ccedil;&otilde;es para:</p>

<ul>
	<li>Completar as suas solicita&ccedil;&otilde;es de cota&ccedil;&otilde;es, propostas de produtos ou servi&ccedil;os oferecidos pelas Institui&ccedil;&otilde;es Parceiras;</li>
	<li>Tra&ccedil;ar perﬁs e tend&ecirc;ncias demogr&aacute;ﬁcas de uso do Website;</li>
	<li>Entrar em contato com Voc&ecirc; para conﬁrmar ou veriﬁcar as informa&ccedil;&otilde;es que Voc&ecirc; nos forneceu;</li>
	<li>Garantir que o Website se mantenha sempre interessante e &uacute;til para Voc&ecirc;, o que poder&aacute; incluir a personaliza&ccedil;&atilde;o de an&uacute;ncios e sugest&otilde;es de conte&uacute;do, produtos ou servi&ccedil;os.</li>
	<li>Proteger a seguran&ccedil;a e a integridade da nossa base de dados;</li>
	<li>Conduzir dilig&ecirc;ncias internas relativas aos neg&oacute;cios da Monee;</li>
	<li>Execu&ccedil;&atilde;o de procedimentos de Know Your Client (&ldquo;KYC&rdquo;), inclusive aqueles dispostos nos termos das leis anticorrup&ccedil;&atilde;o, an- titerrorismo, contra a lavagem de capitais e a evas&atilde;o de divisas, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel e sempre que necess&aacute;rio ou adequado.</li>
</ul>

<p>Caso voc&ecirc; concorde, poderemos usas as informa&ccedil;&otilde;es, tamb&eacute;m, para:</p>

<ul>
	<li>Cruzar informa&ccedil;&otilde;es coletadas por meio do uso de cookies pelas ferramentas do Google Analytics para analisar os perﬁs dos clientes e oferecer servi&ccedil;os mais personalizados;</li>
	<li>Desenvolver, melhorar e oferecer servi&ccedil;os e produtos de terceiros;</li>
	<li>Acessar o Sistema de Informa&ccedil;&otilde;es de Cr&eacute;dito do Banco Central (&ldquo;SCR&rdquo;).</li>
</ul>

<p >Voc&ecirc; deve estar ciente de que o acesso ao SCR &eacute; necess&aacute;rio para a devida avalia&ccedil;&atilde;o e deﬁni&ccedil;&atilde;o de seu risco de cr&eacute;dito e est&aacute; condicio- nado ao consentimento do cliente, conforme leis e regulamentos referentes ao sigilo da atividade ﬁnanceira. Sem o consentimento para usos de suas Informa&ccedil;&otilde;es Pessoais com essa ﬁnalidade, n&atilde;o ser&aacute; vi&aacute;vel a oferta de cr&eacute;dito pelas Institui&ccedil;&otilde;es Parceiras.</p>

<p>Em quais casos poderemos compartilhar estas informa&ccedil;&otilde;es</p>

<p >A viabilidade de certos servi&ccedil;os prestados pela Monee s&oacute; ocorre pelo compartilhamento de algumas dessas informa&ccedil;&otilde;es, o que faze- mos com responsabilidade e seguindo rigorosos par&acirc;metros. Abaixo, citamos os casos nos quais o compartilhamento de infor- ma&ccedil;&otilde;es se faz necess&aacute;rio:</p>

<ul>
	<li>Empresas Conveniadas: compartilhamos suas informa&ccedil;&otilde;es pessoais com a empresa para a qual Voc&ecirc; trabalha para que pos- samos operacionalizar a consigna&ccedil;&atilde;o de parcelas do cr&eacute;dito que Voc&ecirc; contratar.</li>
	<li>Institui&ccedil;&otilde;es Parceiras: para que Voc&ecirc; possa receber cota&ccedil;&otilde;es e propostas de produtos e servi&ccedil;os ﬁnanceiros tais como cart&otilde;es de cr&eacute;dito, seguros, empr&eacute;stimos, ﬁnanciamentos, investimentos e outros, as Institui&ccedil;&otilde;es Parceiras necessitam ter acesso a informa&ccedil;&otilde;es pessoais. Apenas assim &eacute; poss&iacute;vel que Voc&ecirc; receba propostas adequadas ao que Voc&ecirc; procura. As Institui&ccedil;&otilde;es Parceiras ter&atilde;o acesso a essas informa&ccedil;&otilde;es apenas na medida em que forem necess&aacute;rias para que elas possam oferecer produtos e servi&ccedil;os que Voc&ecirc; solicitou ou manifestou interesse.</li>
</ul>

<p>Voc&ecirc; tamb&eacute;m deve estar ciente de que, no momento em que Voc&ecirc; solicita um produto ou servi&ccedil;o das Institui&ccedil;&otilde;es Parceiras por meio do nosso Website, as suas informa&ccedil;&otilde;es ser&atilde;o tratadas por essas institui&ccedil;&otilde;es de acordo as suas pr&oacute;prias pol&iacute;ticas. N&atilde;o submeta pedi- dos de propostas e cota&ccedil;&otilde;es caso n&atilde;o concorde com a forma com a qual as Institui&ccedil;&otilde;es Parceiras tratam as suas informa&ccedil;&otilde;es.</p>

<ul>
	<li>Prote&ccedil;&atilde;o ao cr&eacute;dito: ao solicitar uma simula&ccedil;&atilde;o de cr&eacute;dito no Website, o usu&aacute;rio tem ci&ecirc;ncia de que o Monee ou a institui&ccedil;&atilde;o parceira consultar&atilde;o seus dados de dep&oacute;sitos, aplica&ccedil;&otilde;es em outras institui&ccedil;&otilde;es ﬁnanceiras na forma desta Pol&iacute;tica de Privacidade, bem como informa&ccedil;&otilde;es a seu respeito constantes junto ao aos &oacute;rg&atilde;os de prote&ccedil;&atilde;o ao cr&eacute;dito tais como SPC e Serasa.</li>
	<li>Prote&ccedil;&atilde;o contra fraudes: a Monee pode compartilhar seus dados com bureaux de informa&ccedil;&otilde;es para conﬁrmar sua identi- dade e evitar fraudes, protegendo nossos neg&oacute;cios e a sua identidade.</li>
	<li>Cess&atilde;o de cr&eacute;dito: a Monee poder&aacute; ceder o cr&eacute;dito correspondente a valores tomados por Voc&ecirc; para institui&ccedil;&otilde;es ﬁnanceiras, fundos e outras empresas, conforme previsto na legisla&ccedil;&atilde;o.</li>
	<li>Obriga&ccedil;&atilde;o legal: a Monee pode compartilhar dados pessoais com entidades p&uacute;blicas para cumprir obriga&ccedil;&otilde;es previstas em leis e regulamentos aplic&aacute;veis.</li>
	<li>Ordem judicial: a Monee pode compartilhar dados pessoais em caso de requisi&ccedil;&atilde;o judicial.</li>
	<li>Com a autoriza&ccedil;&atilde;o do cliente: em demais casos, a Monee pode compartilhar informa&ccedil;&otilde;es pessoais quando o cliente consen- tir com o compartilhamento para ﬁnalidades espec&iacute;ﬁcas, ou, havendo a necessidade n&atilde;o prevista de compartilhamento das infor- ma&ccedil;&otilde;es, enviaremos ao cliente uma notiﬁca&ccedil;&atilde;o solicitando sua aprova&ccedil;&atilde;o ou reprova&ccedil;&atilde;o.</li>
</ul>

<p >As informa&ccedil;&otilde;es armazenadas no Website poder&atilde;o ser fornecidas a terceiros, tais como institui&ccedil;&otilde;es ﬁnanceiras e demais agentes de mercado, para ﬁns de an&aacute;lise, elabora&ccedil;&atilde;o e gerenciamento de Propostas de Capta&ccedil;&atilde;o de Recursos, com o objetivo de gerar uma Proposta de Cr&eacute;dito, em conson&acirc;ncia com a Pol&iacute;tica de Avalia&ccedil;&atilde;o de Cr&eacute;dito. As informa&ccedil;&otilde;es submetidas poder&atilde;o ser utilizadas para manuten&ccedil;&atilde;o dos Servi&ccedil;os de Cobran&ccedil;a, gerenciamento de aplica&ccedil;&otilde;es, recomenda&ccedil;&atilde;o de investimentos e manuten&ccedil;&atilde;o de um banco de dados.</p>

<p>Quais direitos garantimos a Voc&ecirc;</p>

<p>A Monee garante os seguintes direitos aos usu&aacute;rios com rela&ccedil;&atilde;o &agrave; coleta e uso de dados por meio de nosso Website:</p>

<ul>
	<li>a conﬁrma&ccedil;&atilde;o de que possu&iacute;mos e utilizamos seus dados pessoais;</li>
	<li>o acesso aos dados detidos por n&oacute;s;</li>
	<li>a corre&ccedil;&atilde;o de eventuais dados incompletos, inexatos ou desatualizados;</li>
	<li>o bloqueio, a elimina&ccedil;&atilde;o ou a anonimiza&ccedil;&atilde;o de dados desnecess&aacute;rios, excessivos ou tratados em desconformidade com a legisla&ccedil;&atilde;o;</li>
	<li>portabilidade dos seus dados pessoais a outra empresa, mediante requisi&ccedil;&atilde;o expressa, observados os nossos segredos comerciais e industriais e os regulamentos oﬁciais sobre o assunto;</li>
	<li>a elimina&ccedil;&atilde;o, quando requerida, dos dados pessoais coletados mediante seu consentimento, na forma da legisla&ccedil;&atilde;o aplic&aacute;vel;</li>
	<li>informa&ccedil;&atilde;o das entidades p&uacute;blicas e privadas com as quais houve uso compartilhado de seus dados;</li>
	<li>informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o fornecer seu consentimento e sobre as consequ&ecirc;ncias da negativa, nos casos em que seus dados forem coletados e tratados mediante consentimento; e</li>
	<li>a revoga&ccedil;&atilde;o do seu consentimento para coleta e tratamento de dados nestes mesmos casos. Quem somos e como contatar-nos</li>
</ul>

<p>O controle das informa&ccedil;&otilde;es pessoais coletadas pelo Website est&aacute; a cargo da Monee Servi&ccedil;os Financeiras Ltda. (&ldquo;Monee&rdquo;), uma socie- dade limitada registrada sob o CNPJ 36.251.922/0001-19, com sede na Rodovia BR-153, Quadra 71A, Lote 6E, Sala 2 &ndash; Vila Bras&iacute;lia, Apa- recida de Goi&acirc;nia - GO, 74.911-410.</p>

<p>Para quaisquer d&uacute;vidas acerca de nossa Pol&iacute;tica de Privacidade ou para exercer seus direitos com rela&ccedil;&atilde;o &agrave; sua privacidade e trata- mento de dados por favor, entre em contato conosco em <a href="mailto:ajuda@monee.com.br">ajuda@monee.com.br.</a></p>

        </p>
      </div>
    </div>
  )
}