import React from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

// Pages
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import PrivatePayroll from './pages/PrivatePayroll';
import ToYou from './pages/ToYou';
import ToCompany from './pages/ToCompany';
import Help from './pages/Help';
import EnterNow from './pages/EnterNow';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UseTerms from './pages/UseTerms';
import Simulation from './pages/Simulation';

// Components
import Header from './components/Header';

export default function Routes() {
  return(
    <BrowserRouter>
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/quem-somos" component={AboutUs}/>
        <Route path="/consignado-privado" component={PrivatePayroll}/>
        <Route path="/para-voce" component={ToYou}/>
        <Route path="/para-empresas" component={ToCompany}/>
        <Route path="/ajuda" component={Help}/>
        <Route path="/entre-agora" component={EnterNow}/>
        <Route path="/simulacao" component={Simulation}/>
        <Route path="/politica-privacidade" component={PrivacyPolicy}/>
        <Route path="/termos-uso" component={UseTerms}/>
        <Redirect from='/*' to='/'/>
      </Switch>
    </BrowserRouter>
  )
}