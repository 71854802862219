import React, { useEffect } from 'react';
import $ from 'jquery';

import './Form.css';

export default function Form() {

  useEffect(() => {
    $('#formSection .form-title').on('click', function() {
      $('#formSection form').slideToggle();
    })
  }, [])
  
  return(
    <div id="formSection">
      <div className="form-content">
        <h2 className="form-title">INDIQUE SUA EMPRESA AGORA</h2>
        <form action="/empresa.php">
          <div className="column-input">
            <div className="row-input">
              <input type="text" name="company" placeholder="Nome da Empresa"/>
              <input type="text" name="employee" placeholder="Nome da Funcionário"/>
            </div>
            <div className="row-input">
              <input type="email" name="companyMail" placeholder="E-mail da Empresa"/>
            </div>
            <div className="row-input">
              <textarea name="message" id="" cols="30" rows="5" placeholder="Mensagem"></textarea>
            </div>
          </div>
          <div className="btn-content">
            <button type="submit" className="btn-submit">ENVIAR A MENSAGEM</button>
          </div>
        </form>
      </div>
    </div>
  )
}