import React from 'react';
import $ from 'jquery';

import './Faq.css';

export default function Faq(props) {
  function toggleAnswer(content) {

    var actualId = content.id;
   
    if ($(`#${actualId} .faq-answer`).css('display') === 'none') {
      $(`#${actualId} .faq-button i`).css('transform', 'rotate(180deg)');
    } else {
      $(`#${actualId} .faq-button i`).css('transform', 'rotate(0deg)');
    }

    $(`#${actualId} .faq-answer`).slideToggle();
  }

  return(
    <div id={'faq-'+props.id} className="faq-content" onClick={(e) => toggleAnswer(e.currentTarget)}>
      <div className="faq-question">
        <h4 className="question">{props.question}</h4>
        <div className="faq-button">
          <i className="fas fa-angle-down"></i>
        </div>
      </div>
      <div className="faq-answer">
        <p className="answer">{props.answer}</p>
      </div>
    </div>
  )
}